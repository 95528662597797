import React from "react";
import Page from "../layout/Page";
import { Link } from "gatsby";
import "../styles/verktyg.scss"

export default function Verktyg({ data }) {

  return (
    <Page title={"Verktyg"}>
        <br/>
        <h1>Hjälpmedel och verktyg</h1>
        <p>Nedan kan du hitta olika hjälpmedel och verktyg för dina studier.</p>
        <ul>
          <li>
          <Link to={"/verktyg/medelvarde/"}>• Räkna ut medelvärde</Link>
          </li>
          <li>
          <Link to={"/verktyg/area-cirkel/"}>• Räkna ut arean på en cirkel</Link>
          </li>
        </ul>

    </Page>
  );
}